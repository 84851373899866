import { Component, OnInit, Inject } from '@angular/core';
import * as jQuery from 'jquery';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  public username: string;
  public password: string;
  public hasSession: boolean;

  constructor(public http: HttpClient) {}

  ngOnInit() {
  }

  loginAction() {
    let config = {
      username: this.username,
      password: this.password
    }
    let body = JSON.stringify(config);
    this.http.post('/api/messages/startSession', body)
    .subscribe((data:any) => this.sessionResponse(data));
  }

  /**
   * Process submit response
   */
  sessionResponse(data) {
    if (data && data.response) {
      this.hasSession = true;
    }
  }

  uploadAction() {

  }
}
