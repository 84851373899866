import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  lang: 'en' | 'es';

  translations = {
      en: {
        'app-gallery': 'Gallery',
        'app-services': 'Services',
        'app-contact': 'Contact',
        'app-copyright': '© Copyright 2006-2020 V.E&E, Inc. All Rights Reserved',
        'contact-name': 'Name',
        'contact-isRequired': 'is required',
        'contact-email': 'Email',
        'contact-invalidEmail': 'Invalid email',
        'contact-phoneNumber': 'Phone number',
        'contact-optional': 'optional',
        'contact-submitResponse': 'Thank you! We will reply as soon as posible.',
        'contact-message': 'Message',
        'contact-submit': 'Submit',
        'contact-leaveContact': 'LEAVE YOUR CONTACT AND A MESSAGE',
        'contact-weWillContactYou': 'WE WILL CONTACT YOU ASAP.',
        'home-homeRepairService': 'Home Repair Services',
        'home-trustedCompany': 'A trusted Company Since 2006',
        'home-hightQuality': 'High quality',
        'home-experience': 'Over 10 years of experience',
        'home-onBudget': 'On budget',
        'home-needPro': 'NEED A PRO FOR YOUR HOME REPAIR?',
        'home-weCanHelp': 'We can help you.',
        'home-recentProjects': 'RECENT PROJECTS',
        'home-bathrooms': 'Bathrooms',
        'home-flooring': 'Flooring',
        'home-carpentry': 'Carpentry',
        'home-seeAllServices': 'See all services',
        'services-services': 'Services',
        'services-requestService': 'Request Service',
        'services-bathroomRemodeling': 'Bathroom Remodeling',
        'services-paintingStaining': 'Painting & Staining',
        'services-plumbing': 'Plumbing',
        'services-tile': 'Tile',
        'services-electrical': 'Electrical',
        'services-flooring': 'Flooring',
        'services-wallsCeilings': 'Walls & Ceilings',
        'services-windowsDoors': 'Windows & Doors',
        'services-cabinets': 'Cabinets',
        'services-doors': 'Doors',
        'services-finishCarpentry': 'Finish Carpentry, Trim & Molding',
        'services-framing': 'Framing',
        'services-closets': 'Closets',
        'services-outbuildingsShedsGazebos': 'Outbuildings, Sheds & Gazebos',
        'services-laminateVinyl': 'Laminate & Vinyl',
        'services-brickStone': 'Brick & Stone',
        'services-hardwood': 'Hardwood',
      },
      es: {
        'app-gallery': 'Fotos',
        'app-services': 'Servicios',
        'app-contact': 'Contáctanos',
        'app-copyright': '© Copyright 2006-2020 VE&amp;E, Inc. Todos los Derechos Reservados',
        'contact-name': 'Nombre',
        'contact-isRequired': 'es obligatorio',
        'contact-email': 'Correo',
        'contact-invalidEmail': 'Correo no válido',
        'contact-phoneNumber': 'Teléfono',
        'contact-optional': 'opcional',
        'contact-submitResponse': 'Gracias! Responderemos cuanto antes.',
        'contact-message': 'Mensaje',
        'contact-submit': 'Enviar',
        'contact-leaveContact': 'DEJA TU CONTACTO Y UN MENSAJE',
        'contact-weWillContactYou': 'TE CONTACTAREMOS CUANTO ANTES.',
        'home-homeRepairService': 'Servicios de Reparacion de Viviendas',
        'home-trustedCompany': 'Una Compañía Confiable desde 2006',
        'home-hightQuality': 'Alta calidad',
        'home-experience': 'Mas de 10 años de experiencia',
        'home-onBudget': 'Buen precio',
        'home-needPro': 'NECESITAS UN PROFESIONAL PARA REPARAR O REMODELAR TU VIVIENDA?',
        'home-weCanHelp': 'Podemos ayurarte.',
        'home-recentProjects': 'PROYECTOS RECIENTES',
        'home-bathrooms': 'Baños',
        'home-flooring': 'Pisos',
        'home-carpentry': 'Carpintería',
        'home-seeAllServices': 'Ver todos los servicios',
        'services-services': 'Servicios',
        'services-requestService': 'Solicitar Servicio',
        'services-bathroomRemodeling': 'Remodelación de Baños',
        'services-paintingStaining': 'Pintura',
        'services-plumbing': 'Plomería',
        'services-tile': 'Mozaicos',
        'services-electrical': 'Electricidad',
        'services-flooring': 'Pisos',
        'services-wallsCeilings': 'Paredes & Techos',
        'services-windowsDoors': 'Ventanas & Puertas',
        'services-cabinets': 'Gabinetes',
        'services-doors': 'Puertas',
        'services-finishCarpentry': 'Carpintería General',
        'services-framing': 'Marcos',
        'services-closets': 'Armarios',
        'services-outbuildingsShedsGazebos': 'Gazebos',
        'services-laminateVinyl': 'Laminado',
        'services-brickStone': 'Ladrillos',
        'services-hardwood': 'Madera',
      }
  }

  constructor() {
    const lang = this.getlang();
    this.lang = lang;
  }

  translate(key: string) {
    const lang = this.lang;
    return this.translations[lang][key] || '';
  }

  getTranslations() {
    const lang = this.lang;
    return this.translations[lang];
  }

  getlang() {
    const lang = localStorage.getItem('lang');
    return lang === 'es' ? 'es' : 'en';
  }

  setLang(lang: 'es' | 'en') {
    const lSLang = lang === 'es' ? 'es' : 'en';
    localStorage.setItem('lang', lSLang);
  }
}
