import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '../translate.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  readonly translations = this.translate.getTranslations();

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit() {
  }
}
