import { Component, OnInit, Inject } from '@angular/core';
import * as jQuery from 'jquery';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  translations = this.translate.getTranslations();

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit() {
  }
}
