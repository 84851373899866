import { Component, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { MatDialog } from '@angular/material';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.css' ]
})
export class HomeComponent implements OnInit {

  readonly translations = this.translate.getTranslations();

  constructor(
    public dialog: MatDialog,
    public sessionService: SessionService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
  }
}
