import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '../translate.service';

/**
 * Interfaces Window and Document
 * to add remoteResponse property
 */
declare global {
  interface Window { remoteResponse: any; }
  interface Document { remoteResponse: any; }
}

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.css']
})

export class ContactUsFormComponent {

  @ViewChild('contactForm') contactForm;
  create:any = {};
  submitted;
  contactError: string = "";
  readonly translations = this.translate.getTranslations();

  constructor(
    public http: HttpClient,
    private translate: TranslateService
  ) {}

  /**
   * Submit the form
   */
  onSubmit() {
    let body = JSON.stringify(this.create);
    this.http.post('/api/messages/create', body)
    .subscribe((data:any) => this.sendEmailResponse(data));
  }

  /**
   * Process submit response
   */
  sendEmailResponse(data) {
    this.submitted = true;
    this.contactForm.resetForm();
  }
}
