import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SessionService } from './session.service';
import { NgImageSliderComponent } from 'ng-image-slider';
import { TranslateService } from './translate.service';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'VEE';
  @ViewChild('nav') slider: NgImageSliderComponent;
  galleryHidden: boolean = true;

  images: Array<object> = [

    {image: '/assets/slideshow/0_vee_home_repair_project_1.jpeg'},
    {image: '/assets/slideshow/0_vee_home_repair_project_2.jpeg'},
    {image: '/assets/slideshow/0_vee_home_repair_project_3.jpeg'},
    {image: '/assets/slideshow/0_vee_home_repair_project_4.MP4'},
    {image: '/assets/slideshow/0_vee_home_repair_project_5.MP4'},

    {image: '/assets/slideshow/vee_home_repair_project_1.png'},
    {image: '/assets/slideshow/vee_home_repair_project_2.png'},
    {image: '/assets/slideshow/vee_home_repair_project_3.png'},
    {image: '/assets/slideshow/vee_home_repair_project_4.png'},
    {image: '/assets/slideshow/vee_home_repair_project_5.png'},
    {image: '/assets/slideshow/vee_home_repair_project_6.png'},
    {image: '/assets/slideshow/vee_home_repair_project_7.png'},
    {image: '/assets/slideshow/vee_home_repair_project_8.png'},
    {image: '/assets/slideshow/vee_home_repair_project_9.png'},
    {image: '/assets/slideshow/vee_home_repair_project_10.png'},
    {image: '/assets/slideshow/vee_home_repair_project_11.png'},
    {image: '/assets/slideshow/vee_home_repair_project_12.png'},
    {image: '/assets/slideshow/vee_home_repair_project_13.png'},
    {image: '/assets/slideshow/vee_home_repair_project_14.png'},
    {image: '/assets/slideshow/vee_home_repair_project_15.png'},
    {image: '/assets/slideshow/vee_home_repair_project_16.png'},
    {image: '/assets/slideshow/vee_home_repair_project_17.png'},
    {image: '/assets/slideshow/vee_home_repair_project_18.png'},
    {image: '/assets/slideshow/vee_home_repair_project_19.png'},
    {image: '/assets/slideshow/vee_home_repair_project_20.png'},
    {video: '/assets/slideshow/vee_home_repair_project_21.MP4'},
    {image: '/assets/slideshow/vee_home_repair_project_22.png'},
    {image: '/assets/slideshow/vee_home_repair_project_23.png'},
    {image: '/assets/slideshow/vee_home_repair_project_24.MP4'},
    {image: '/assets/slideshow/vee_home_repair_project_25.jpeg'},
    {image: '/assets/slideshow/vee_home_repair_project_26.jpeg'},

    {image: '/assets/slideshow/24.jpeg'},
    {image: '/assets/slideshow/25.jpeg'},
    {image: '/assets/slideshow/26.jpeg'},
    {image: '/assets/slideshow/27.jpeg'},
    {image: '/assets/slideshow/28.jpeg'},
    {image: '/assets/slideshow/29.jpeg'},
    {image: '/assets/slideshow/30.jpeg'},
    {image: '/assets/slideshow/31.jpeg'},
    {image: '/assets/slideshow/32.jpeg'},
    {image: '/assets/slideshow/33.jpeg'},
    {image: '/assets/slideshow/34.jpeg'},
    {image: '/assets/slideshow/35.jpeg'},
    {image: '/assets/slideshow/36.jpeg'}
  ];
  readonly translations = this.translate.getTranslations();

  constructor(
    public dialog: MatDialog,
    public sessionService: SessionService,
    public translate: TranslateService
  ) {}

  ngOnInit () {
  }

  openGallery() {
    this.galleryHidden = false;
    this.slider.showLightbox();
  }

  setLang(lang) {
    this.translate.setLang(lang);
    location.reload();
  }
}
